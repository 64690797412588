<template>
    <el-row>
        <el-col class="mb-3">
            <el-card class="profile" shadow="never">
                <div slot="header" class="">

                    <b style="font-size:18px;color: green;margin-top: 5px;padding-right:10px;">{{user.name}}</b>
                    <div v-if="user.disabled" class="text-danger ml-1" style="display: inline-block;">
                        您的账户未激活,{{preset.activate_notice}}
                    </div>
                    <span class="text-danger ml-2"
                          v-if="!user.disabled && ( null == profile.email || 0 === profile.email.length )">
                        <br /> 您需要输入邮箱才可以继续使用其他功能。
                    </span>
                </div>

                <div class="mt-3">
                    <el-form :model="profile" ref="profile" label-width="auto" class="">
                        <el-form-item prop="user_id" label="登录名">
                            <el-input v-model="profile.user_id" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item
                                prop="name"
                                label="姓名"
                                :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' },]">
                            <el-input v-model="profile.name" placeholder="您需填写姓名才可以继续使用其他功能。"></el-input>
                        </el-form-item>
                        <el-form-item prop="email"
                                      label="邮箱">
                            <el-input v-model="profile.email" placeholder="您需要输入邮箱才可以继续使用其他功能。"></el-input>
                        </el-form-item>
                        <el-form-item prop="mobile"
                                      label="手机">
                            <el-input v-model="profile.mobile" placeholder="选填"></el-input>
                        </el-form-item>
                        <el-form-item
                                :disabled="true"
                                prop="department"
                                label="部门">
                            <el-input v-model="profile.department"></el-input>
                        </el-form-item>
                        <el-form-item
                                :disabled="true" autocomplete = "off"
                                prop="old_password"
                                label="旧密码">
                            <el-input v-model="profile.old_password" placeholder="如需改密码，请在此输入旧密码"></el-input>
                        </el-form-item>
                        <el-form-item
                                :disabled="true"
                                prop="password" autocomplete = "off"
                                label="密码">
                            <el-input v-model="profile.password" placeholder="如需改密码，请在此输入新密码"></el-input>
                        </el-form-item>

                        <el-form-item v-if="!user.disabled">
                            <el-button type="primary" @click="submitForm">提交</el-button>
                            <el-button @click="resetForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
        </el-col>
    </el-row>

</template>

<script>

    import {mapState, mapMutations} from "vuex";

    export default {
        name: "profile",
        data() {
            return {
                profile: {
                    name: '',
                    user_id: '',
                    department: '',
                    mobile: '',
                    email: '',
                    old_password:'',
                    password:''
                },
                sending: false
            }
        },
        computed: {
            ...mapState(['user', 'process','preset']),

        },
        mounted() {
            this.resetForm();
        },
        methods: {
            ...mapMutations('user', ['setUserInfo']),
            resetForm() {
                this.profile.name = this.user.name;
                this.profile.user_id = this.user.user_id;
                this.profile.department = this.user.department;
                this.profile.mobile = this.user.mobile;
                this.profile.email = this.user.email;
                this.profile.password = '';
                this.profile.old_password = '';
            },
            submitForm() {
                const vm = this;
                this.$refs['profile'].validate((valid) => {
                    if (valid) {
                        this.$api('Profile', this.profile, true).then((response) => {
                                vm.setUserInfo(response.data.data);
                                this.$notify({
                                    title: '成功',
                                    message: '档案更新完成!',
                                    type: 'success'
                                })
                                    this.$router.push('/');

                            }
                        );
                    } else {
                        this.$notify({
                            title: '错误',
                            message: '请按照指示填写正确的信息',
                            type: 'error'
                        });
                        return false;
                    }
                });
            },
            sendNoticeEmail() {
                const vm = this;
                vm.$affirm('我们将向管理员发送一封提醒邮件, 是否继续?').then(() => {
                    vm.$api('User.Remind', {}, true).then(() => {
                        vm.$messageBox.success('邮件已经发出,请等待管理员回复');
                        setTimeout(function () {
                            vm.sending = false;
                        }, 60000);
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">

    .profile {

        .image {
            width: 36px;
            height: 36px;
        }
    }

</style>

<style lang="scss">
    .el-form--label-top {
        .el-form-item__label {
            padding: 0;
            margin-bottom: 0;
        }
    }
</style>
