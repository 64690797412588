<template>
    <el-card class="profile" shadow="never">
        <el-row :gutter="20" class="px-4">
            <h6><i class="el-icon-right"></i> 操作文档</h6>
            <ul>
                <li v-for="x in pdfs" :key="x.name" :icon="x.icon" :command="x.link">
                    <span style="width: 400px;display: inline-block">{{ x.name }}</span>
                    <a :href="x.link" target="_blank"><i class="el-icon-search"></i> 查看</a>
                </li>
            </ul>

            <el-divider></el-divider>
            <h6><i class="el-icon-right"></i> 操作视频</h6>
            <ul>
                <li v-for="x in mp4s" :key="x.name" :icon="x.icon" :command="x.link">
                    <span style="width: 400px;display: inline-block">{{ x.name }}</span>
                    <a :href="x.link" target="_blank"><i class="el-icon-video-play"></i> 播放</a>
                </li>
            </ul>

            <el-divider></el-divider>
            <h6><i class="el-icon-right"></i> 相关连接</h6>
            <ul>
                <li v-for="x in links" :key="x.name" :icon="x.icon" :command="x.link">
                    <span style="width: 400px;display: inline-block">{{ x.name }}</span>
                    <a :href="x.link" target="_blank"><i class="el-icon-link"></i> 跳转</a>
                </li>
            </ul>
        </el-row>
    </el-card>
</template>

<script>
import ManualList from "./mixins/manual-list-mixin";

export default {
    name: "manual-list",
    data() {
        return {}
    },
    mixins: [ManualList],
}
</script>

<style scoped lang="scss">
@import "../global";


</style>
