export default {
    name: "manual-list",
    data() {
        return {
            list:[],
        }
    },
    computed: {
        pdfs(){
            return this.list.filter((i)=>parseInt(i.type)===0);
        },
        mp4s(){
            return this.list.filter((i)=>parseInt(i.type));
        },
        links(){
            return [{name:"下载腾讯会议",link:"https://meeting.tencent.com/download.html"}];
        }
    },
    mounted() {
        this.$api('Admin.Manual.Manuals',{},true).then(res=>{
            this.list = res.data.data;
        });
    },
    methods: {
    }
}
